import React from 'react';
import styles from './index.module.css';
import gorg from '../../img/gorg-ivan.png'
import brick from '../../img/brick.jpg'


const Banner = ({ text }) => {
  return (
    <div className={styles.outterShell}>
      <div className={styles.text}>
        <div className={styles.flex}>
        <img style={{transform: 'rotateY(180deg)'}}src={gorg} alt="Dragon" className={styles.dragonImage} />
        <h1>{text}</h1>
        <img src={gorg} alt="Dragon" className={styles.dragonImage} />

        </div>
      </div>
    </div>
  );
};

export default Banner;