import React, { useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { FaDiscord, FaInstagram, FaYoutube, FaTwitter , FaLinkedin } from 'react-icons/fa';


import styles from './index.module.css';


const Navbar = ({navItems}) => {

// Process the navItems array into an array of objects with name and to properties
const processedNavItems = [];
for (let i = 0; i < navItems.length; i += 2) {
  processedNavItems.push({ name: navItems[i], to: navItems[i + 1] });
}
  return (
    <div>
    <div className={styles.outerShell}>
    <nav className={styles.navbar}>
        <div className={styles.companyName}>
        <NavLink  to="/" className={styles.navLink}>Chronix Pulse</NavLink>
        </div >
        <div className={styles.navElements}>
        {/* <NavLink  to="/arrivi" className={styles.navLink}>About Us</NavLink>
        <NavLink  to="/arrivi" className={styles.navLink}>Color Catcher</NavLink>
        <NavLink  to="/arrivi" className={styles.navLink}>Games</NavLink>
        <NavLink  to="/arrivi" className={styles.navLink}>Support</NavLink> */}
         {processedNavItems.map((item, index) => (
              <NavLink key={index} to={item.to} className={styles.navLink}>
                {item.name}
              </NavLink>
            ))}

      </div>
      <div className={styles.social}>
        <a target="_blank" rel="noopener noreferrer"  href="https://x.com/chronixpulse" className={styles.navLink}><FaTwitter /></a>
        <a   target="_blank" rel="noopener noreferrer" href="https://instagram.com/chronixpulse" className={styles.navLink}><FaInstagram /></a>
        <a target="_blank" rel="noopener noreferrer"  href="https://linkedin.com/company/chronix-pulse" className={styles.navLink}><FaLinkedin /></a>

      </div>
    </nav>
    </div>
    </div>
    
  );
};

export default Navbar;