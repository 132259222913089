import styles from './index.module.css';
import image1 from '../../img/404.png'

const ColorCatcher = () => {
    return (
      <div className={styles.pageWidth}>
        <div className={styles.flex}>
            <h2 className={styles.h2}>404 Page not Found</h2>
            <img src={image1} className={styles.image} alt="not found"></img>
        </div>
        </div>
   
    );
  };
  
  export default ColorCatcher;