import React, { useState } from 'react';
import { FaApple, FaGooglePlay, FaGamepad, FaSteam } from 'react-icons/fa';

import Gif from '../../img/image3.gif'
import phone from '../../img/phone.webp'
import ios from '../../img/ios.svg'
import google from '../../img/google.svg'

import styles from './index.module.css';
import YoutubeEmbed from "../../components/YoutubeEmbed";
import Banner from '../../components/Banner'
import DarkText from '../../components/DarkText'

const ColorCatcher = () => {
  return (
    <div className={styles.pageWidth}>
        
    <div className={styles.landingPage}>
      <div className={styles.landingText}>
        {/* <h1 className={styles.gameTitle}>Color Catcher</h1> */}
        
      </div>
      
      

     
       </div>
      <Banner text="Gameplay"></Banner>
      <div className={styles.backgroundSection}>
      <div className={styles.descriptionText}>
      <DarkText>
        <h2 className={styles.h2}>Dive into "Gem Catch," the ultimate dexterity arcade game on your mobile!</h2>
      <p className={styles.gameText}>Gemfall Catcher - A mobile arcade game with simple and intuitive gameplay, perfect for passing time when bored, traveling, or waiting at the doctor's office! Challenge yourself and compete against others to achieve the highest score by collecting the most gems. Do you have the quick reactions and finger dexterity to become the top gem collector?</p>
      </DarkText>
         </div>

         <div className={styles.gameDemoFlex}>
          <div className={styles.gameDemoImage}>
            <div className={styles.imgMerge}>
          <img className={styles.phone} src={phone} alt="phone"></img>
          <img className={styles.gameDemoGif} src={Gif} alt="Gif"></img>
          </div>
          </div>
         <div className={styles.gameDemoText}>
         <DarkText> 
          <p>
          
          <strong>GemFall Catch</strong> - The Ultimate Dexterity Arcade Game!
          
          </p>
         
          <ul>
            <li><strong>Endless Fun</strong> - The game speeds up and becomes more challenging the longer you play</li>
            <li><strong>Easy Controls </strong>- Intuitive gameplay for all ages - just jump in and start catching shapes</li>
            <li><strong>Compete Globally </strong>- Climb the leaderboards and challenge players worldwide
            </li>
            </ul>
            <p>Gameplay Highlights</p>
            <ul>
<li><strong>Guide Your Hero</strong>  and catch falling treasures and score big
</li>
<li><strong>Colorful Chaos</strong> as you navigate through the cascade of colourful shapes
</li>
<li><strong>Test Your Reflexes</strong> as the game accelerates, master complex formations to boost your score
</li>

          </ul>
          <p>With "Gem Catch," every tap counts! How long can you keep up?
          </p>
          </DarkText>
          <div className={styles.downloads}>
          <img className={styles.ios} src={ios} alt="ios"></img>
          <img className={styles.google} src={google} alt="google"></img>
          </div>
         </div>

         </div>
         </div>
</div>
  );
};

export default ColorCatcher;