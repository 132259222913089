import React, { useState } from 'react';
import { FaApple, FaGooglePlay, FaGamepad, FaSteam } from 'react-icons/fa';


import styles from './index.module.css';
import YoutubeEmbed from "../../components/YoutubeEmbed";
import Banner from '../../components/Banner'
import Darknet from '../../img/darknet_logo_blue.webp'
import Logo from '../../img/CXP-text.svg'
import Aleksey from '../../img/CXP - Aleksey.webp'
import Ivan from '../../img/CXP - Ivan.webp'
import Maksym from '../../img/CXP - Maksym.webp'
import Semyon from '../../img/CXP - Semyon.webp'
import Extra from '../../img/wizzard.webp'
import DarkText from '../../components/DarkText'
const Home = () => {
  return (
    
    <div className={styles.pageWidth}>
      
    <div className={styles.landingPage}>
      <div className={styles.landingText}>
        <img loading="lazy" className={styles.logoImg} src={Logo} alt="logo" ></img>
          <h1 style={{color:'white'}}>We are Chronix Pulse, an independent, self-published game developer based in Amsterdam, Netherlands.
          </h1>
      
      <div className={styles.descriptionText}>
        <p style={{color:'white'}}>Players and their experiences come first to us and we learn from things that you are telling us.
        </p>
        <p style={{color:'white'}}>We are not following a traditional management approach with hierarchical and command-and-control structure because the team decides together what is best for the game. This way we do our best to offer fresh and unique experiences to players that are trying out our games. We build our games and community in an open, welcoming, and collaborative environment, where everyone can share their ideas and help us make our games better for everyone to enjoy. It is also expected that throughout this life-long journey there will be failures and successes from which we will learn to improve.
        </p>
        <div className={styles.companyAd}>
       {/* <YoutubeEmbed embedId="Cg0KskQyeRg" /> */}
      </div>
      </div >
      </div>

</div>
      <Banner text="Our Values"></Banner>
      <div style={{ maxHeight: "700px" }}className={styles.dirtTexture}></div>
      <div className={styles.backgroundSection}>
     
      <div className={styles.valuesOuterDiv}>
    <div className={styles.valueText}>
      <DarkText>
      <p><strong>Crafting Unique Games:</strong> We are learning to create original, captivating games that connect with players personally</p>
      <p><strong>Enthusiasm & Dedication </strong>- Passion drives our work</p>
      <p><strong>Courage & Comradery </strong>- We support and uplift each other in a communicative and welcoming culture</p>
      <p><strong>Balanced Development</strong> - We strive for harmony in creating fun, rewarding games that promote healthy, non-addictive behavior</p>
      </DarkText>
    </div>
    <div className={styles.valueImg}>
        <img src={Darknet} alt="Darknet"></img>
    </div>
      </div>
      </div>
      
      <Banner text="Our Team"></Banner>
      <div style={{ maxHeight: "781px" }} className={styles.dirtTexture}></div>
      <div className={styles.backgroundSection}>
  <ul className={styles.teamInfoContainer}>
  <li className={styles.teamMember}>
      <img loading="lazy" src={Aleksey} alt="Aleksey Popov" className={styles.teamMemberImage} />
      <div className={styles.teamMemberDetails}>
      <DarkText>
        <h2>Aleksey Popov</h2>
        <p>Finance Administrator</p>
        </DarkText>
      </div>
    </li>
    <li className={styles.teamMember}>
      <img loading="lazy" src={Ivan} alt="Ivan Khlyzov" className={styles.teamMemberImage} />
      <div className={styles.teamMemberDetails}>
      <DarkText>
        <h2>Ivan Khlyzov</h2>
        <p>Producer & Game Designer</p>
        </DarkText>
      </div>
    </li>
    <li className={styles.teamMember}>
      <img loading="lazy" src={Maksym} alt="Maksym Malynovskyi" className={styles.teamMemberImage} />
      <div className={styles.teamMemberDetails}>
      <DarkText>
        <h2>Maksym Malynovskyi</h2>
        <p>Multidisciplinary Specialist</p>
        </DarkText>
      </div>
    </li>
    <li className={styles.teamMember}>
      <img loading="lazy" src={Semyon} alt="Semyon Ravnushkin" className={styles.teamMemberImage} />
      <div className={styles.teamMemberDetails}>
      <DarkText>
        <h2>Semyon Ravnushkin</h2>
        <p>Game Developer & DevOps Engineer</p>
        </DarkText>
      </div>
    </li>
    <li className={styles.teamMember}>
      <img src={Extra} alt="Extra developer" className={styles.teamMemberImage} />
      <div className={styles.teamMemberDetails}>
        <DarkText>
          <h2>Unknown wizard</h2>
        <p>Wizard trainee</p>
        </DarkText>
      </div>
    </li>
    <li loading="lazy" className={styles.teamMember}>
      <img src={Extra} alt="Extra developer" className={styles.teamMemberImage} />
      <div className={styles.teamMemberDetails}>
        <DarkText><h2>Unknown wizard</h2>
        <p>Wizard trainee</p>
        </DarkText>
      </div>
    </li>
    <li loading="lazy" className={styles.teamMember}>
      <img src={Extra} alt="Extra developer" className={styles.teamMemberImage} />
      <div className={styles.teamMemberDetails}>
        <DarkText><h2>Unknown wizard</h2>
        <p>Wizard trainee</p>
        </DarkText>
      </div>
    </li>
    <li loading="lazy" className={styles.teamMember}>
      <img src={Extra} alt="Extra developer" className={styles.teamMemberImage} />
      <div className={styles.teamMemberDetails}>
        <DarkText><h2>Unknown wizard</h2>
        <p>Wizard trainee</p>
        </DarkText>
      </div>
    </li>
    
  </ul>
</div>


</div>
  );
};

export default Home;