
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Home from './pages/Home';
import ColorCatcher from './pages/ColorCatcher'
import Navbar from './components/Navbar'
import NotFound from './pages/404'; // Create a NotFound component
import styles from './index.module.css';

function App() {
  return (
    <Router>
      <div className={styles.outterShell}>
        <Navbar navItems={['About Us', '/', 'Gemfall Catcher', '/gemfall-catcher']} />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route  path="/gemfall-catcher" element={<ColorCatcher />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Navbar navItems={['About Us', '/',  'Gemfall Catcher', '/gemfall-catcher']} />
      </div>
    </Router>
  );
}

export default App;
